<template>
  <el-row justify="end">
    <el-col :span="4" style="text-align: right">
      <el-button type="text" class="LinkText" @click="downLoadData">下载</el-button>
    </el-col>
  </el-row>
  <search-form-content ref="searchFormRef" :form-items="items" :fields="fields" :fetch-data="fetchData" :row-key="'id'"
    :default-page-size="10">
  </search-form-content>
</template>

<script setup>
import { ref } from 'vue'
import SearchFormContent from '@/components/base/SearchFormContent'
import { getConsumptionFlow, downloadConsumptionFlow } from '@/request/api/pay-record'
import filters from '@/base/filters/base'
import { ElMessage, ElLoading } from 'element-plus'
import downloadFile from '@/base/download-file'

const searchFormRef = ref(null)

const items = {
  timeRange: {
    type: 'daterange',
    label: '选择日期',
    value: filters.getDefaultDuration(), // 默认最近一周时间范围
    disableDate: (time) => {
      return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now()
    },
  },
}

const fields = [
  {
    id: 'type',
    label: '流水类型',
    format: (spent) => {
      return spent === 0 ? '收款入账' : '充值出账'
    },
  },
  {
    id: 'money',
    label: '流水金额',
    format: (spent) => {
      return filters.thousands(spent)
    },
  },
  {
    id: 'origin_balance',
    label: '旧钱包余额',
    format: (spent) => {
      return filters.thousands(spent)
    },
  },
  {
    id: 'final_balance',
    label: '最新钱包余额',
    format: (spent) => {
      return filters.thousands(spent)
    },
  },
  { id: 'time', label: '时间' },
]

const fetchData = async (params) => {
  let res = await getConsumptionFlow(params)

  return {
    page: {
      total: res.total ? res.total : 0,
      page: params.page ? params.page : 1,
      pagesize: params.pagesize ? params.pagesize : 10,
    },
    data: res.data && res.data.length > 0 ? res.data : [],
  }
}

const downLoadData = async () => {
  if (searchFormRef.value.getTableData().length === 0) {
    return ElMessage.error('暂无数据')
  }
  const loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '下载中，请稍后...',
  })
  let res = await downloadConsumptionFlow(searchFormRef.value.getSearchForm())
  // 处理返回的文件流
  downloadFile(res, '流水记录')
  loading.close()
}
</script>

<style scoped>
.total-data {
  color: var(--el-text-color-regular);
}

.selected-data {
  color: var(--el-text-color-regular);
  font-size: 14px;
  margin-top: 15px;
}
</style>
