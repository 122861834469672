<template>
    <!-- <el-row justify="end"> -->
    <!-- <el-col :span="4" style="text-align: right"> -->
    <!-- <el-button type="text" class="LinkText" @click="downLoadData">下载</el-button> -->
    <!-- </el-col> -->
    <!-- </el-row> -->
    <search-form-content ref="searchFormRef" :form-items="items" :fields="fields" :fetch-data="fetchData"
        :row-key="'account_id'" :format-status="formatStatus" :default-page-size="10">
    </search-form-content>
</template>
  
<script setup>
import { ref } from 'vue'
import SearchFormContent from '@/components/base/SearchFormContent'
import { getDeduction } from '@/request/api/pay-record'
import filters from '@/base/filters/base'
import downloadFile from '@/base/download-file'
import { ElMessage, ElLoading } from 'element-plus'

const searchFormRef = ref(null)

const items = {
    timeRange: {
        type: 'daterange',
        label: '选择日期',
        value: filters.getDefaultDuration(), // 默认最近一周时间范围
        disableDate: (time) => {
            return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now()
        },
    },
}

const fields = [
    {
        id: 'account_id',
        label: '账户id',

    },
    {
        id: 'media_type',
        label: '媒体平台',
        format: (media_type) => {
            return media_type === 1 ? 'Facebook' : media_type
        },
    },
    {
        id: 'money',
        label: '减款金额',
        format: (spent) => {
            return filters.thousands(spent)
        },
    },
    {
        id: 'status',
        label: '状态',
        content: (status) => {
            return status
        },
    },
    { id: 'add_time', label: '创建时间' },
]

const formatStatus = (item) => {
    if ('status' !== item.column.property) return ''
    if ('成功' === item.row.status) return 'color-success'
    if ('失败' === item.row.status) return 'color-danger'
    return 'color-primary'
}

const fetchData = async (params) => {
    let res = await getDeduction(params)

    return {
        page: {
            total: res.total ? res.total : 0,
            page: params.page ? params.page : 1,
            pagesize: params.pagesize ? params.pagesize : 10,
        },
        data: res.data && res.data.length > 0 ? res.data : [],
    }
}

const downLoadData = async () => {
    if (searchFormRef.value.getTableData().length === 0) {
        return ElMessage.error('暂无数据')
    }
    const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
    })
    let res = await downloadConsumptionFlow(searchFormRef.value.getSearchForm())
    // 处理返回的文件流
    downloadFile(res, '减款记录')
    loading.close()
}
</script>
  
<style scoped>
.total-data {
    color: var(--el-text-color-regular);
}

.selected-data {
    color: var(--el-text-color-regular);
    font-size: 14px;
    margin-top: 15px;
}
</style>
  