<template>
  <el-form ref="payRecordFormRef" :inline="true" :model="payRecordForm" style="padding-top: 15px">
    <el-row justify="space-between">
      <el-col :span="20">
        <el-form-item label="选择日期" prop="timeRange">
          <el-date-picker
            v-model="payRecordForm.timeRange"
            type="daterange"
            size="small"
            clearable
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="X"
            style="margin-left: 14px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="付款方式" prop="type">
          <el-select v-model="payRecordForm.type" clearable placeholder="请选择付款方式" size="small">
            <el-option label="支付宝" value="1"></el-option>
            <el-option label="微信" value="2"></el-option>
            <el-option label="银联" value="3"></el-option>
            <el-option label="连连支付" value="4"></el-option>
            <el-option label="打款水单" value="5"></el-option>
            <el-option label="PingPong" value="6"></el-option>
            <el-option label="万里汇" value="7"></el-option>
            <el-option label="空中云汇" value="8"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="payRecordForm.status" clearable placeholder="请选择状态" size="small">
            <el-option label="审核中" value="1"></el-option>
            <el-option label="已到账" value="2"></el-option>
            <el-option label="已拒绝" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 14px">
          <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
          <el-button @click="resetFormBtn" size="small">重置</el-button>
        </el-form-item>
      </el-col>
      <el-col :span="4" style="text-align: right">
        <el-button type="text" class="LinkText" @click="downLoadData">下载</el-button>
      </el-col>
    </el-row>
  </el-form>

  <el-table
    ref="payRecordListRef"
    :data="payRecordList"
    style="width: 100%"
    :header-cell-style="{
      background: 'var(--el-table-row-hover-background-color)',
    }"
  >
    <el-table-column property="recharge_no" label="订单编号" />
    <el-table-column label="订单内容">
      <template #default="{ row }">
        {{ 3 == row.receive_type ? '个号充值' : '钱包充值' }}
      </template>
    </el-table-column>

    <el-table-column property="orderSum" label="付款金额">
      <template #default="{ row }">
        <span v-if="row.currency == 'cny'">¥{{ $filters.thousands(row.amount) }}</span>
        <span v-else>${{ $filters.thousands(row.amount) }}</span>
      </template>
    </el-table-column>
    <el-table-column property="total_amount" label="到账金额">
      <template #default="{ row }">
        <el-tooltip
          v-if="$store.getters.userInfo.exc === 'shoplazza'"
          trigger="hover"
          placement="top"
          effect="light"
          width="200"
        >
          <el-button type="text">
            ${{
              $filters.thousands(
                (row.total_amount ? row.total_amount * 1.0 : 0) + (row.bonus_amount ? row.bonus_amount * 1.0 : 0)
              )
            }}
          </el-button>
          <template #content>
            <el-space direction="vertical" :size="10" fill style="width: 100%">
              <span>到账金额：${{ $filters.thousands(row.total_amount) }}</span>
              <span :span="24">到账礼金：${{ $filters.thousands(row.bonus_amount ? row.bonus_amount * 1.0 : 0) }}</span>
            </el-space>
          </template>
        </el-tooltip>
        <span v-else>${{ $filters.thousands(row.total_amount) }}</span>
      </template>
    </el-table-column>
    <el-table-column property="handling_fee" label="手续费">
      <template #default="{ row }">
        <span>${{ $filters.thousands(row.handling_fee) }}</span>
      </template>
    </el-table-column>
    <el-table-column property="type" label="付款方式" />
    <el-table-column label="状态" width="150">
      <template #default="{ row }">
        <template v-if="row.receive_type == 2">
          <el-tag v-if="row.crm_status == 0">待审核</el-tag>
          <el-tag type="success" v-else-if="row.crm_status == 1">已到账</el-tag>
          <el-tag type="danger" v-else>已拒绝</el-tag>
        </template>
        <el-tag type="success" v-else>已到账</el-tag>
      </template>
    </el-table-column>
    <el-table-column property="is_yc" label="是否YC操作" />
    <!-- <el-table-column property="u_name" label="操作人" /> -->
    <el-table-column property="create_date" label="创建时间" />
    <!-- <el-table-column property="arrivaltime" label="到账时间" /> -->
    <template #empty>
      <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
    </template>
  </el-table>

  <!-- 分页器 -->
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="queryInfo.page"
    :page-sizes="[5, 10]"
    :page-size="queryInfo.pagesize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    background
  >
  </el-pagination>

  <!-- 下载付款记录弹窗 -->
  <el-dialog v-model="downLoadDialogVisible" title="下载付款记录" width="790px">
    <!-- 内容主体区域 -->
    <el-form ref="downLoadFormRef" label-width="100px">
      <el-form-item label="选择时间" class="timeRange">
        <el-date-picker
          v-model="payTimeRange"
          type="daterange"
          size="small"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="X"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="付款方式">
        <el-select v-model="paymentMethod" placeholder="请选择付款方式" size="small">
          <el-option label="支付宝" value="1"></el-option>
          <el-option label="微信" value="2"></el-option>
          <el-option label="银联" value="3"></el-option>
          <el-option label="连连支付" value="4"></el-option>
          <el-option label="打款水单" value="5"></el-option>
          <el-option label="PingPong" value="6"></el-option>
          <el-option label="万里汇" value="7"></el-option>
          <el-option label="空中云汇" value="8"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择字段" class="downLoadForm">
        <el-transfer
          v-model="payValue"
          style="text-align: left; display: inline-block"
          :left-default-checked="['handling_fee', 'type', 'crm_status', 'pay_date', 'is_yc']"
          :right-default-checked="[]"
          :titles="['可选字段', '已选字段']"
          :button-texts="['移除', '添加']"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }"
          :data="payData"
        >
        </el-transfer>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="downLoadFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="downLoadFormBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElLoading } from 'element-plus'

export default {
  name: 'PayList',
  data() {
    return {
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      total: 5,

      payRecordForm: {
        timeRange: '',
        type: '',
        status: '',
      },
      payRecordList: [],
      downLoadDialogVisible: false,
      payTimeRange: '',
      paymentMethod: '',
      payValue: ['amount', 'u_name', 'total_amount', 'create_date'],
      payData: [
        {
          key: 'amount',
          label: '付款金额',
          disabled: true,
        },
        {
          key: 'total_amount',
          label: '到账金额',
          disabled: true,
        },
        {
          key: 'handling_fee',
          label: '手续费',
        },
        {
          key: 'type',
          label: '付款方式',
        },
        {
          key: 'u_name',
          label: '操作人',
          disabled: true,
        },
        {
          key: 'crm_status',
          label: '状态',
        },
        {
          key: 'create_date',
          label: '创建时间',
          disabled: true,
        },
        {
          key: 'pay_date',
          label: '到账时间',
        },
        {
          key: 'is_yc',
          label: '是否YC操作',
        },
      ],
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getpayRecordList()
  },
  methods: {
    queryFormBtn() {
      this.getpayRecordList()
    },
    resetFormBtn() {
      this.$refs.payRecordFormRef.resetFields()
      this.getpayRecordList()
    },
    async getpayRecordList() {
      const { data: res } = await this.$http.post('cloudfb/recharge_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        timeRange: this.payRecordForm.timeRange,
        type: this.payRecordForm.type,
        status: this.payRecordForm.status,
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      this.payRecordList = res.list
      this.total = res.data.total
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getpayRecordList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getpayRecordList()
    },
    downLoadData() {
      if (this.payRecordList == '') {
        return this.$message({
          showClose: true,
          message: '暂无数据',
          type: 'warning',
        })
      }
      this.downLoadDialogVisible = true
    },
    downLoadFormBtn() {
      var payValue = this.payValue.join(',')
      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
      })

      this.$http({
        method: 'post',
        url: 'cloudfb/recharge_export',
        data: {
          token: this.userInfo.token,
          timeRange: this.payTimeRange,
          field: payValue,
          type: this.paymentMethod,
        },
        responseType: 'blob', // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = '付款记录.xls'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })
      this.downLoadDialogVisible = false
    },
    downLoadFormClose() {
      this.downLoadDialogVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.timeRange {
  :deep(.el-date-editor.el-input__inner) {
    width: 220px !important;
  }
}

.downLoadForm {
  .el-checkbox {
    width: 100px;
  }

  :deep(.el-transfer__button) {
    display: block;
    min-height: 32px;
    padding: 9px 15px;
    font-size: 12px;
  }

  :deep(.el-transfer__button:nth-child(2)) {
    margin-left: 0 !important;
  }
}
</style>
