<template>
  <!-- 微信支付宝支付 -->
  <el-row class="payment">
    <el-col>
      <el-card class="box-card" style="min-height:700px;">
        <template #header>
          <div class="card-header">
            <span class="SubHead">收银台</span>
          </div>
        </template>
        <div class="text item">
          <h4>订单提交成功，请尽快付款</h4>
          <h6>订单编号：{{ payId }}</h6>
          <h5>
            扫一扫付款：<span>¥ {{ orderAmount }}</span>
          </h5>
          <div class="time" v-show="isShade > 0 && payType == '支付宝'">
            剩余支付时间
            <span>{{ minute }}:</span>
            <span>{{ seconds }}</span>
          </div>
          <div class="payType">
            <img src="../../assets/ALIPAY.png" alt="" class="pay-method-img" v-if="payType == '支付宝'" />
            <img src="../../assets/WECHATPAY.png" alt="" class="pay-method-img" v-else />
            <img :src="payCode" alt="" class="qrcode" />

            <div class="qrcodeShade" @click="reFresh" v-show="isShade <= 0 && payType == '支付宝'">
              <span>请刷新</span>
              <i class="el-icon-refresh"></i>
            </div>
            <div class="scan">
              <span class="iconfont icon-saoma vb"></span>
              <span class="tip">
                打开手机<span v-if="payType == '支付宝'">支付宝</span><span v-else>微信</span><br />
                扫一扫继续支付
              </span>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Payment',
  props: ['id'],
  data() {
    return {
      minute: '',
      seconds: '',
      interval: '',
      countdownTime: 15,
      isShade: 1,
      orderAmount: 0,
      payId: '',
      payType: '',
      payCode: '',
      count:undefined,
    }
  },
  mounted() {
    this.getPayRequire()
    this.getPayInfo()
    this.setTimer()
    this.setTimeData(this.countdownTime * 60 * 1000)
  },
  methods: {
    async getPayRequire() {
      //  每秒请求一次
      this.count = window.setInterval(async () => {
        const { data: res } = await this.$http.post('cloudfb/set_recharge', {
          id: this.id
        })

        if (res.code == 200) {
          clearInterval(this.count)
          this.$message.success('该订单充值成功')
          this.$router.push('/recharge_list')
        }
      }, 1000)
    },
    async getPayInfo() {
      const { data: res } = await this.$http.post('cloudfb/pay_order', {
        id: this.id
      })
      if (res.code == 100) return this.$message.error(`${res.msg}`)
      if (res.code == 300) {
        this.$router.push('/login')
      }

      this.orderAmount = res.data.amount
      this.payId = res.data.recharge_no
      this.payType = res.data.type
      this.payCode = res.data.url
    },

    // 支付倒计时S
    setTimeData(cm) {
      // this.hour = Math.floor((cm / 1000 / 60 / 60) % 24)
      // this.hour = this.hour.toString().length == 1 ? '0' + this.hour : this.hour
      this.minute = Math.floor((cm / 1000 / 60) % 60)
      this.minute = this.minute.toString().length == 1 ? '0' + this.minute : this.minute
      this.seconds = Math.floor((cm / 1000) % 60)
      this.seconds = this.seconds.toString().length == 1 ? '0' + this.seconds : this.seconds
    },
    setTimer() {
      let _this = this
      this.interval = setInterval(function() {
        _this.payCountdown(_this.countdownTime * 60 * 1000)
        clearInterval(_this.interval)
      }, 1000)
    },

    payCountdown(cm) {
      this.isShade = cm
      if (cm >= 0) {
        // this.hour = Math.floor((cm / 1000 / 60 / 60) % 24)
        // this.hour = this.hour.toString().length == 1 ? '0' + this.hour : this.hour
        this.minute = Math.floor((cm / 1000 / 60) % 60)
        this.minute = this.minute.toString().length == 1 ? '0' + this.minute : this.minute
        this.seconds = Math.floor((cm / 1000) % 60)
        this.seconds = this.seconds.toString().length == 1 ? '0' + this.seconds : this.seconds
        let _msThis = this
        setTimeout(function() {
          cm -= 1000
          _msThis.payCountdown(cm)
        }, 1000)
      }
    },
    // 支付倒计时E

    async reFresh() {
      this.isShade = 1
      this.payCountdown(this.countdownTime * 60 * 1000)
      const { data: res } = await this.$http.post('cloudfb/set_qrcode', {
        id: this.id
      })
      if (res.code == 100) return this.$message.error(`${res.msg}`)
      if (res.code == 300) {
        this.$router.push('/login')
      }
      this.orderAmount = res.data.amount
      this.payCode = res.data.url
      this.payId = res.data.recharge_no
    }
  }
}
</script>

<style lang="less" scoped>
.payment {
  h4 {
    font-weight: 600;
    color: #262626;
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
  }
  h6 {
    font-size: 16px;
    font-weight: 400;
    color: #595959;
    line-height: 24px;
    margin-bottom: 4px;
    text-align: center;
  }
  h5 {
    font-size: 16px;
    font-weight: 400;
    color: #595959;
    line-height: 32px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0;
    span {
      color: #f56c6c;
      font-weight: 600;
      font-size: 24px;
    }
  }
  .payType {
    position: relative;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    width: 208px;
    height: 303px;
    margin: 24px auto 0px;
    padding: 24px 0px;
    .pay-method-img {
      height: 24px;
      margin: 0px auto;
      display: block;
    }
    .qrcode {
      width: 185px;
      height: 185px;
      display: block;
      margin: 16px auto;
    }
    .qrcodeShade {
      position: absolute;
      top: 65px;
      left: 10px;
      width: 185px;
      height: 185px;
      text-align: center;
      background: rgba(0, 0, 0, 0.6);
      padding-top: 40px;
      box-sizing: border-box;
      cursor: pointer;
      span {
        display: block;
        font-size: 16px;
        color: #fff;
        margin-bottom: 10px;
      }
      i {
        font-size: 60px;
        color: #fff;
      }
    }
    .scan {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      .vb {
        margin-right: 15px;
        display: block;
        font-size: 40px;
        color: #2b82ff;
      }
      .tip {
        font-size: 14px;
        line-height: 22px;
        color: #595959;
      }
    }
  }
}
.time {
  font-size: 16px;
  color: #595959;
  text-align: center;
  margin-top: 10px;
  span {
    color: #f56c6c;
  }
}
</style>
