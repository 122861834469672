<template>
  <!-- 付款记录 -->
  <el-row style="margin-bottom: 20px">
    <el-col>
      <el-card>
        <ul class="pastAccount">
          <li>
            <span>
              <i class="iconfont icon-moneybag"></i>
            </span>
            <div>
              <p class="Text">可用余额</p>
              <div v-if="$store.getters.userInfo.exc === 'shoplazza'">
                <el-tooltip trigger="hover" placement="top" effect="light" width="200">
                  <el-button type="text"><span class="balance-info" :class="numType == 1 ? 'red' : ''">${{
                    $filters.thousands((balance ? balance * 1.0 : 0) + (bonus_balance ? bonus_balance * 1.0 : 0))
                  }}</span>
                  </el-button>
                  <template #content>
                    <el-space direction="vertical" :size="10" fill style="width: 100%">
                      <span>钱包余额：${{ $filters.thousands(balance) }}</span>
                      <span :span="24">礼金余额：${{ $filters.thousands(bonus_balance) }}</span>
                      <span :span="24">注：赠予礼金仅能用于Facebook账户充值</span>
                    </el-space>
                  </template>
                </el-tooltip>
              </div>
              <div class="MainHead" v-else :class="numType == 1 ? 'red' : ''">${{ $filters.thousands(balance) }}</div>
            </div>
            <el-tooltip placement="bottom" effect="light">
              <template #content>
                <span v-if="credit <= 0">审核通过的付款记录，YinoLink易诺将按照实际到账金额充值到您的钱包中，<br />您可自由分配到广告账户使用</span>
                <span v-else>审核通过的付款记录，YinoLink易诺将按照实际到账金额充值到您的钱包中，<br />您可自由分配到广告账户使用。因后付客户每月初出账单，数据会有误差</span>
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </li>
          <li>
            <span>
              <i class="iconfont icon-facebook"></i>
            </span>
            <div>
              <p class="Text">账户余额</p>
              <div class="MainHead" :class="ccType == 1 ? 'red' : ''">${{ $filters.thousands(count) }}</div>
              <span class="HelpText">
                前往<router-link to="/advertising_list"><span class="LinkText">账户列表</span></router-link>
              </span>
            </div>
            <el-tooltip content="Bottom center" placement="bottom" effect="light">
              <template #content>
                <span v-if="credit <= 0">您所有状态为正常的Facebook广告账户中的余额总计</span>
                <span v-else>您所有状态为正常的Facebook广告账户中的余额总计，会占用钱包余额，<br />建议您及时清零闲置账户的余额</span>
              </template>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </li>
          <li v-show="credit > 0">
            <span>
              <i class="iconfont icon-edushouxin"></i>
            </span>
            <div>
              <p class="Text">授信额度</p>
              <div class="MainHead">${{ credit }}</div>
              <span style="font-size: 0">0</span>
            </div>
            <el-tooltip content="您在YinoLink易诺已批准的授信额度" placement="bottom" effect="light">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>

  <!-- 开户历史 -->
  <el-row>
    <el-col>
      <el-card class="box-card">
        <el-tabs v-model="activeName">
          <!-- 付款记录列表 -->
          <!-- <el-tab-pane label="付款记录" name="pay"> -->
          <!-- <PayList /> -->
          <!-- </el-tab-pane> -->

          <!-- 充值记录列表 -->
          <el-tab-pane label="充值记录" name="recharge">
            <RechargeList />
          </el-tab-pane>

          <!-- 清零记录列表 -->
          <el-tab-pane label="清零记录" name="reset">
            <ResetList />
          </el-tab-pane>

          <!-- 消费流水列表 -->
          <el-tab-pane label="消费流水" name="consumption">
            <ConsumptionFlow />
          </el-tab-pane>

          <!-- 减款记录列表 -->
          <el-tab-pane label="减款记录" name="deduction" v-if="showDeduction">
            <Deduction />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import PayList from './recordList/PayList'
import RechargeList from './recordList/RechargeList.vue'
import ResetList from './recordList/ResetList.vue'
import ConsumptionFlow from './recordList/ConsumptionFlow.vue'
import Deduction from './recordList/Deduction.vue'

export default {
  name: 'payRecord',
  components: {
    PayList,
    RechargeList,
    ResetList,
    ConsumptionFlow,
    Deduction,
  },
  data() {
    return {
      balance: '',
      bonus_balance: '',
      count: '',
      credit: '',
      //控制可用余额变红
      numType: '',
      //控制账户余额变红
      ccType: '',
      activeName: 'recharge',
      showDeduction: localStorage.getItem('credit_type') === '2' ? true : false,
    }
  },
  mounted() {
    this.getBalance()
  },
  methods: {
    async getBalance() {
      const { data: res } = await this.$http.post('cloudfb/recharge_data')
      this.balance = res.data.balance
      this.bonus_balance = res.data.bonus_balance
      this.count = res.data.count
      this.credit = res.data.credit
      this.numType = res.data.num_type
      this.ccType = res.data.cc_type
    },
  },
}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.pastAccount {
  display: flex;
  justify-content: flex-start;

  li {
    position: relative;
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 1px solid #d9d9d9;

    span {
      position: relative;
      width: 15%;
      height: 75%;
    }

    i.iconfont {
      font-size: 55px;
      color: #2b82ff;
      position: absolute;
      top: 0;
    }

    i.el-icon-warning-outline {
      position: absolute;
      top: 16px;
      right: 30px;
      font-size: 18px;
      color: #909399;
      cursor: pointer;
    }

    div {
      width: 50%;
      margin-bottom: 10px;
    }
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.red {
  color: #f56c6c;
}

/* 字体大小颜色 */
.balance-info {
  font-size: 24px;
  font-weight: 600;
}
</style>
